// Toggle the postal address card if the postal_same_as_street_address
// is checked
$(document).on('turbolinks:load', function() {
  var postal_same_as_street_address = $("#client_postal_same_as_street_address");

    if (postal_same_as_street_address.is(':checked')) {
      $("#postal-address-card").hide();
    }

  postal_same_as_street_address.click(function() {
    if(this.checked) {
      $("#postal-address-card").hide(500);
    } else {
      $("#postal-address-card").show(500);
    }
  });
});